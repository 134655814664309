import { useApolloClient } from "@apollo/client"
import { useLocation } from "@reach/router"
import ShopHeader from "components/ShopHeader"
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js"
import { Link, navigate } from "gatsby"
import Cookie from "js-cookie"
import { Button, Minus, Modal, Plus } from "nzk-react-components"
import React, { useMemo, useState } from "react"
// @ts-ignore
import { useShoppingCart } from "use-shopping-cart"
import CREATE_CHECKOUT_SESSION from "../graphql/shop_createCheckoutSession.graphql"
import * as s from "./index.styles"
import config from "../../../../../config"
import { calculateShipping } from "./utils"
import useModal from '../../../../hooks/useModal'
import Recommendations from './components/Recommendations'

const ShopCheckout = () => {
  const {
    redirectToCheckout,
    cartDetails,
    decrementItem,
    removeItem,
    incrementItem,
    totalPrice,
    currency,
    formattedTotalPrice,
  } = useShoppingCart()
  const location = useLocation()
  const client = useApolloClient()
  const [confirmRemoveModal, setConfirmRemoveModal] = useState<string | null>(null)

  const createSession = async () => {
    const { data } = await client.mutate({
      mutation: CREATE_CHECKOUT_SESSION,
      variables: {
        rawCartDetails: JSON.stringify(cartDetails),
        successUrl: `${location.origin}/shop/order/complete`,
        cancelUrl: `${location.origin}/shop/order/failed`,
      },
    })
    Cookie.set("nzk-order-checkout", data.shop_createCheckoutSession._id)
    redirectToCheckout({
      sessionId: data.shop_createCheckoutSession.stripeSessionId,
    })
  }

  const items = useMemo(
    () => Object.keys(cartDetails).map(k => cartDetails[k]),
    [cartDetails]
  )

  const createPaypalOrder = async () => {
    return fetch(`${config.eduServerEndpoint}/shop/order/paypal`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify({
        items,
      }),
    })
      .then(res => {
        return res.json()
      })
      .then(orderData => {
        return orderData.id
      })
  }

  const paypalOrderApproved = async data => {
    return fetch(
      `${config.eduServerEndpoint}/shop/order/${data.orderID}/paypal/capture`,
      {
        method: "post",
      }
    )
      .then(res => {
        return res.json()
      })
      .then(order => {
        if (order.paid) {
          Cookie.set("nzk-order-checkout", order._id)
          navigate("/shop/order/complete")
        }
      })
  }

  const hasShipping = useMemo(() => {
    return (
      items.reduce((acc, item) => {
        return acc + (item.metadata.digital ? 0 : 1)
      }, 0) > 0
    )
  }, [items])

  const shippingCost = useMemo(() => {
    if (!hasShipping) return null
    return calculateShipping(items)
  }, [hasShipping, items])

  const shippableToCountryCode = countryCode => {
    return (
      items.filter(item => {
        return (
          !item.metadata.digital &&
          item.metadata.countryCodes.indexOf(countryCode) < 0
        )
      }).length === 0
    )
  }

  const onPaypalShippingChange = (data, actions) => {
    if (!shippableToCountryCode(data.shipping_address.country_code)) {
      return actions.reject()
    }
    return actions.resolve()
  }

  const EDITABLE_SKUS = [
    "escape-from-conform-prison-softcover",
    "escape-from-conform-prison-hardcover",
  ]

  const getInfo = item => {
    const editable = EDITABLE_SKUS.indexOf(item.sku) >= 0
    let label
    let editLink

    if (
      item.sku === "escape-from-conform-prison-softcover" ||
      item.sku === "escape-from-conform-prison-hardcover"
    ) {
      const urlSearchParams = new URLSearchParams()
      Object.keys(item.metadata.personalisedOptions).map(key => {
        urlSearchParams.set(key, item.metadata.personalisedOptions[key])
        return null
      })
      editLink = `/shop/products/escape-from-conform-prison/personalise?id=${
        item.id
      }&${urlSearchParams.toString()}`
    }
    if (item.sku === "escape-from-conform-prison-softcover") {
      label = `Softcover - ${item.metadata.personalisedOptions.username} - ${item.metadata.personalisedOptions.animalName}`
    } else if (item.sku === "escape-from-conform-prison-hardcover") {
      label = `Hardcover - ${item.metadata.personalisedOptions.username} - ${item.metadata.personalisedOptions.animalName}`
    } else if (item.shortDescription) {
      label = item.shortDescription
    }

    return {
      label,
      editable,
      editLink,
    }
  }

  const {
    Modal: ConfirmRemoveModal,
    open: openConfirmRemoveModal,
    dismiss: dismissConfirmRemoveModal,
  } = useModal(
    <Modal actions={[
      <Button key='yes' theme='confirm' size='regular' onClick={() => {
        removeItem(confirmRemoveModal)
        dismissConfirmRemoveModal()
      }}>Yes</Button>,
      <Button key='no' theme='red' size='regular' onClick={() => {
        dismissConfirmRemoveModal()
      }}>No</Button>
    ]} title="Are you sure?" message='Are you sure you want to remove this from your basket?' dismiss={() => {
      dismissConfirmRemoveModal()
    }} />,
    () => {
      setConfirmRemoveModal(null)
    }
  )

  const onRemoveItem = (itemId) => {
    setConfirmRemoveModal(itemId)
    openConfirmRemoveModal()
  }

  return (
    <s.Wrapper>
      { ConfirmRemoveModal }
      <ShopHeader noCheckout />
      <s.Content>
        <s.Title>Your Basket</s.Title>
        <Recommendations recommendations={[
          { sku: 'NZK-COMPLETE-BUNDLE', recommendSku: 'READING-COMPREHENSION-COMPANION-PACK' },
          { sku: 'books-cards-pack', recommendSku: 'READING-COMPREHENSION-COMPANION-PACK' },
          { sku: 'MULTIPACK-WRITING-ACTIVITIES', recommendSku: 'MULTIPACK-WRITING-ACTIVITIES-2' },
          { sku: 'nzkbook6', recommendSkus: ['BOOK-6-COMPREHENSION-PACK', 'books-cards-pack'] },
          { sku: 'NZK-006', recommendSkus: ['BOOK-6-COMPREHENSION-PACK', 'NZK-COMPLETE-BUNDLE'] },
        ]} />
        <s.Table>
          <thead>
            <tr>
              <td>Product</td>
              <td>Price</td>
              <td>Quantity</td>
              <td>Total</td>
            </tr>
          </thead>
          <tbody>
            {items.map(item => {
              const info = getInfo(item)
              return (
                <tr key={item.id}>
                  <td>
                    <s.Product>
                      <div className="product--image">
                        <img src={item.image} alt={item.name} />
                      </div>
                      <div className="product--title">
                        <div>{item.name}</div>
                        {info.label && (
                          <div className="product-info">{info.label}</div>
                        )}
                        <div className="product--actions">
                          <Button
                            size="x-small"
                            theme="purple"
                            onClick={() => onRemoveItem(item.id)}
                          >
                            Remove
                          </Button>
                          {info.editable && (
                            <Button
                              size="x-small"
                              theme="orange"
                              onClick={() => navigate(info.editLink)}
                            >
                              Edit
                            </Button>
                          )}
                        </div>
                      </div>
                    </s.Product>
                  </td>
                  <td>
                    <s.Price>{item.formattedPrice}</s.Price>
                  </td>
                  <td>
                    <s.Quantity>
                      <div>{item.quantity}</div>
                      <div className="quantity--control">
                        <Button
                          size="x-small"
                          theme="purple"
                          round
                          onClick={() => {
                            if (item.quantity === 1) {
                              onRemoveItem(item.id)
                            } else {
                              decrementItem(item.id, { count: 1 })
                            }
                          }}
                        >
                          <Minus />
                        </Button>
                        <Button
                          size="x-small"
                          theme="purple"
                          round
                          onClick={() => incrementItem(item.id, { count: 1 })}
                        >
                          <Plus />
                        </Button>
                      </div>
                    </s.Quantity>
                  </td>
                  <td>
                    <s.Total>{item.formattedValue}</s.Total>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </s.Table>
        <s.MobileContent>
          {items.map(item => {
            const info = getInfo(item)
            return (
              <s.MobileItem key={`mobile-${item.id}`}>
                <s.Product>
                  <div className="product--image">
                    <img src={item.image} alt={item.name} />
                  </div>
                  <s.MobileProductContent>
                    <div className="product--title">
                      <div>{item.name}</div>
                      <div className="product--actions">
                        <Button
                          size="x-small"
                          theme="purple"
                          onClick={() => removeItem(item.id)}
                        >
                          Remove
                        </Button>
                        {info.editable && (
                          <Button
                            size="x-small"
                            theme="orange"
                            onClick={() => navigate(info.editLink)}
                          >
                            Edit
                          </Button>
                        )}
                      </div>
                    </div>
                    <s.Price>
                      <u>Price</u>: <b>{item.formattedPrice}</b>
                    </s.Price>
                    <s.Quantity>
                      <div>
                        <u>Quantity</u>: <b>{item.quantity}</b>
                      </div>
                      <div className="quantity--control">
                        <Button
                          size="x-small"
                          theme="purple"
                          round
                          onClick={() => decrementItem(item.id, { count: 1 })}
                        >
                          <Minus />
                        </Button>
                        <Button
                          size="x-small"
                          theme="purple"
                          round
                          onClick={() => incrementItem(item.id, { count: 1 })}
                        >
                          <Plus />
                        </Button>
                      </div>
                    </s.Quantity>
                    <s.Total>
                      <u>Total</u>: <b>{item.formattedValue}</b>
                    </s.Total>
                  </s.MobileProductContent>
                </s.Product>
              </s.MobileItem>
            )
          })}
        </s.MobileContent>
        <s.SubTotal>
          <div>Subtotal</div>
          <div>{formattedTotalPrice}</div>
        </s.SubTotal>
        {hasShipping && !shippingCost && (
          <s.ShippingCosts>
            <div />
            <div>Shipping costs calculated at checkout</div>
          </s.ShippingCosts>
        )}
        {hasShipping && shippingCost && (
          <>
            <s.ShippingCosts>
              <div>Shipping costs</div>
              <div>{shippingCost.formattedAmount}</div>
            </s.ShippingCosts>
            <s.ShippingCosts>
              <div>Total</div>
              <div>
                {new Intl.NumberFormat("en-us", {
                  style: "currency",
                  currency,
                }).format((shippingCost.amount + totalPrice) / 100)}
              </div>
            </s.ShippingCosts>
          </>
        )}
        <s.Actions>
          <Link to="/shop">
            <Button size="small" theme="purple">
              {items.length > 0 ? "Continue shopping" : "Start shopping"}
            </Button>
          </Link>
          {items.length > 0 && (
            <Button size="small" theme="primary" onClick={createSession}>
              Checkout
            </Button>
          )}
        </s.Actions>
        {items.length > 0 && (
          <s.Actions>
            <s.OtherPaymentOptions>
              <div>Or pay with</div>
              <PayPalButtons
                style={{
                  layout: "horizontal",
                  color: "white",
                  shape: "pill",
                  height: 40,
                }}
                // @ts-ignore
                onShippingChange={onPaypalShippingChange}
                createOrder={createPaypalOrder}
                onApprove={paypalOrderApproved}
              />
            </s.OtherPaymentOptions>
          </s.Actions>
        )}
        <s.Info>
          By completing an order with us, we will consider you to be interested
          in our range of products and services, and may contact you from time
          to time about them. You may unsubscribe from these communications at
          any time. For more information on how to unsubscribe, our privacy
          practices, and how we are committed to protecting and respecting your
          privacy, please review our{" "}
          <a href="/privacy-policy" target="_blank" rel="noreferrer noopener">
            Privacy Policy.
          </a>
        </s.Info>
      </s.Content>
    </s.Wrapper>
  )
}

export default props => {
  const { currency } = useShoppingCart()

  return (
    <PayPalScriptProvider
      options={{ "client-id": config.paypalClientId, currency }}
    >
      <ShopCheckout {...props} />
    </PayPalScriptProvider>
  )
}
