import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import Recommendation from './Recommendation'

interface IProps {
  sku: string
  recommendSkus: string[]
}

const Book6Recommendation = (props: IProps) => {
  const { sku, recommendSkus } = props

  const { activitiesProduct, bookSeriesProduct } = useStaticQuery(graphql`
    {
      activitiesProduct: strapiShopProducts(
        slug: { eq: "book-6-companion-pack" }
      ) {
        id
        slug
        name
        thumbnail {
          url
        }
        digital
        skus {
          id
          sku
          title
          warehouse
          thumbnail {
            url
          }
          pricings {
            slug
            shippingRate
            shopShippingRate {
              id
              country {
                id
                locale
              }
            }
            countries {
              currencyCode
              code
              locale
              conversionRateToUSD
            }
            currency
            amount
          }
        }
      }
      bookSeriesProduct: strapiShopProducts(
        slug: { eq: "complete-book-series" }
      ) {
        id
        slug
        name
        thumbnail {
          url
        }
        digital
        skus {
          id
          sku
          title
          warehouse
          thumbnail {
            url
          }
          pricings {
            slug
            shippingRate
            shopShippingRate {
              id
              country {
                id
                locale
              }
            }
            countries {
              currencyCode
              code
              locale
              conversionRateToUSD
            }
            currency
            amount
          }
        }
      }
    }
  `)

  return <>
    { recommendSkus[0] && <Recommendation
      sku={sku}
      recommendSku={recommendSkus[0]}
      product={activitiesProduct}
      reason='Over 90 pages of reading comprehension activities, quizzes, and answers based on Book 6: The Sea Lion of Endless Ocean.' /> }
    { recommendSkus[1] && <Recommendation
      sku={sku}
      recommendSku={recommendSkus[1]}
      product={bookSeriesProduct}
      reason='The first 5 books in the Night Zookeeper Adventure Series.' /> }
  </> 
}

export default Book6Recommendation
