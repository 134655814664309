import React from 'react'
import SEO from '../../components/SEO'
import ShopCheckout from '../../components/pages/Shop/ShopCheckout'
import ShopLayout from '../../layouts/ShopLayout'

const ShopCheckoutPage = () => <ShopLayout>
  <SEO noIndex />
  <ShopCheckout />
</ShopLayout>

export default ShopCheckoutPage
